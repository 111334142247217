<template>
    <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <label>Name:</label>
                <b-form-input
                    type="text"
                    placeholder="Name"
                    v-model="addObject.name"
                />
            </b-form-group>
            <b-form-group>
                <label>Media type:</label>
                <v-select
                    v-model="addObject.type"
                    :reduce="item => item.id"
                    label="name"
                    :clearable="false"
                    :options="types"
                />
            </b-form-group>
            <b-form-group>
                <label>Media file:</label>
                <div v-if="!addObject.file || addObject.file.length === 0">
                    <b-overlay :show="fileUploading">
                        <b-form-file
                            v-model="elementFile"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                        />
                    </b-overlay>
                    <b-progress v-if="fileUploading" class="mt-1" :value="progress" :max="100" show-progress></b-progress>
                </div>
                <div v-else>
                    <b-row>
                        <b-col>
                            <b-button variant="success" @click="downloadFile()">
                                <feather-icon
                                    icon="DownloadIcon"
                                    class="mr-50"
                                />
                                <span class="align-middle">Download</span>
                            </b-button>
                        </b-col>
                        <b-col>
                            <b-button variant="danger" @click="addObject.file = ''">
                                <feather-icon
                                    icon="Trash2Icon"
                                    class="mr-50"
                                />
                                <span class="align-middle">Remove</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-form-group>
            <b-form-group v-if="addObject.type === 0">
                <label>Duration:</label>
                <b-form-input
                    type="number"
                    placeholder="Duration"
                    v-model="addObject.duration"
                />
            </b-form-group>
        </template>
        <template #modal-footer>

            <b-button variant="danger" @click="addModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addSignageElement">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BModal, BRow, BCol, BFormFile, BProgress, BOverlay} from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BFormFile,
            BRow,
            BCol,
            BProgress,
            BOverlay,
            vSelect
        },
        data() {
            return {

                fileUploading: false,
                progress: 0,

                addObject: {
                    name: '',
                    type: 0,
                    file: '',
                    duration: 30
                },
                addModalActive: false,
                elementFile: null,

                types: [
                    {
                        id: 0,
                        name: 'Image'
                    },
                    {
                        id: 1,
                        name: 'Video'
                    }
                ]
            }
        },
        methods: {
            open() {
                this.addModalActive = true
            },
            addSignageElement() {
                const thisIns = this
                const loadPromise = this.$http.post(`/api/management/v1/digital_signage/${  this.$route.params.id}/elements`, {
                    name: this.addObject.name,
                    type: this.addObject.type,
                    file: this.addObject.file,
                    duration: Number(this.addObject.duration)
                })
                loadPromise.then(function(response) {
                    thisIns.$printSuccess('Element added')
                    thisIns.addModalActive = false
                    thisIns.addObject = {
                        name: '',
                        type: 0,
                        file: '',
                        duration: 30
                    }
                    thisIns.$emit('itemAdded', response.data.id)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            downloadFile() {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0, 4))) {
                    window.location.href = `/api/management/v1/digital_signage_file/${  this.addObject.file}`
                } else {
                    window.open(`/api/management/v1/digital_signage_file/${  this.addObject.file}`, '_blank')
                }

            }
        },
        watch: {
            elementFile() {
                if (!this.elementFile) return
                const thisIns = this
                const formData = new FormData()
                this.fileUploading = true
                formData.append('file', this.elementFile)

                this.progress = 0

                this.$http.post('/api/management/v1/digital_signage_file/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress(progressEvent) {
                        thisIns.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                }).then(function(response) {
                    thisIns.$printSuccess('File uploaded')
                    thisIns.addObject.file = response.data

                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.elementFile = null
                    thisIns.fileUploading = false
                })
            }
        }
    }

</script>
<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>